import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Axios from 'axios';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyle = makeStyles(({spacing}) => ({
  root: {
    marginTop: spacing(2),
    marginBottom: spacing(4),
    padding: '32px 16px',
  },
}));

export function AdminUpdateSponsorForm({sponsor}) {
  const classes = useStyle();
  let {splashImage} = sponsor;

  const splashImageUri = splashImage && splashImage.uri ? splashImage.uri : '';

  const [updatedSponsor, setUpdatedSponsor] = useState(sponsor);
  const [newUri, setNewUri] = useState(splashImageUri);

  let history = useHistory();

  const updateSponsor = async (event) => {
    event.preventDefault();

    let newlyUpdatedSponsor = {
      ...updatedSponsor,
      splashImage: {uri: newUri},
    };
    try {
      await Axios.post(`/api/sponsor/${sponsor._id}`, newlyUpdatedSponsor);
      history.go(0).push('/admin/sponsors');
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) =>
    setUpdatedSponsor({
      ...updatedSponsor,
      [e.target.name]: e.target.value,
    });

  return (
    <Container
      component={Paper}
      elevation={0}
      maxWidth="sm"
      className={classes.root}
    >
      <form onSubmit={updateSponsor}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Sponsor Name"
              id="name"
              name="name"
              value={updatedSponsor.name}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Image example <image-name.png>"
              id="uri"
              name="uri"
              value={newUri}
              onChange={(e) => setNewUri(e.target.value)}
              fullWidth
            />
          </Grid>
          <Typography variant="h6" style={{paddingLeft: '16px'}}>
            {"Sponsor URL's"}
          </Typography>

          <Grid item xs={12}>
            <TextField
              label="Final Project Drive Url"
              id="finalProjectUrl"
              name="finalProjectUrl"
              type="url"
              value={updatedSponsor.finalProjectUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Tour Url"
              id="tourUrl"
              name="tourUrl"
              type="url"
              value={updatedSponsor.tourUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Utility Portal"
              id="websiteUrl"
              name="websiteUrl"
              type="url"
              value={updatedSponsor.websiteUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Local Utility About Page"
              id="aboutUrl"
              name="aboutUrl"
              type="url"
              value={updatedSponsor.aboutUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Second Local Utility About Page"
              id="about2Url"
              name="about2Url"
              type="url"
              value={updatedSponsor.about2Url}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Regional Provider About Page"
              id="regionalAboutUrl"
              name="regionalAboutUrl"
              type="url"
              value={updatedSponsor.regionalAboutUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Conservation Programs Url"
              id="programsUrl"
              name="programsUrl"
              type="url"
              value={updatedSponsor.programsUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Careers"
              id="careersUrl"
              name="careersUrl"
              type="url"
              value={updatedSponsor.careersUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Social"
              id="socialUrl"
              name="socialUrl"
              type="url"
              value={updatedSponsor.socialUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Solution Form"
              id="solutionFormUrl"
              name="solutionFormUrl"
              type="url"
              value={updatedSponsor.solutionFormUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              value="objData"
            >
              Update Sponsor
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

AdminUpdateSponsorForm.propTypes = {
  sponsor: PropTypes.object.isRequired,
};
