import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Axios from 'axios';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

const useStyle = makeStyles(({spacing}) => ({
  root: {
    marginTop: spacing(2),
    marginBottom: spacing(4),
    padding: '32px 16px',
  },
}));

export const AdminAddSponsorForm = ({setOpen}) => {
  const classes = useStyle();
  const [newSponsor, setObjData] = useState({});
  const [newUri, setNewUri] = useState('');

  let history = useHistory();

  const updateObjData = (e) => {
    setObjData({...newSponsor, [e.target.name]: e.target.value});
  };

  const addSponsor = async (event) => {
    event.preventDefault();

    const formattedSponsor = {
      ...newSponsor,
      splashImage: {uri: newUri},
    };
    try {
      await Axios.post('/api/sponsor', formattedSponsor);
      setOpen((prevState) => !prevState);
      history.go(0).push('/admin/sponsors');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container
      component={Paper}
      elevation={0}
      maxWidth="sm"
      className={classes.root}
    >
      <form onSubmit={addSponsor}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Sponsor Name"
              id="name"
              name="name"
              value={newSponsor.name}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Image example <image-name.png>"
              id="uri"
              name="uri"
              value={newUri}
              onChange={(e) => setNewUri(e.target.value)}
              fullWidth
            />
          </Grid>

          <Typography variant="h6" style={{paddingLeft: '16px'}}>
            Sponsor URL's
          </Typography>

          <Grid item xs={12}>
            <TextField
              label="Final Project Drive Url"
              id="finalProjectDriveUrl"
              name="finalProjectDriveUrl"
              type="url"
              value={newSponsor.finalProjectUrl}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Tour Url"
              id="tourUrl"
              name="tourUrl"
              type="url"
              value={newSponsor.tourUrl}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Utility Portal"
              id="websiteUrl"
              name="websiteUrl"
              type="url"
              value={newSponsor.websiteUrl}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Local Utility About Page"
              id="aboutUrl"
              name="aboutUrl"
              type="url"
              value={newSponsor.aboutUrl}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Second Local Utility About Page"
              id="about2Url"
              name="about2Url"
              type="url"
              value={newSponsor.about2Url}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Regional Provider About Page"
              id="regionalAboutUrl"
              name="regionalAboutUrl"
              type="url"
              value={newSponsor.regionalAboutUrl}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Conservation Programs Url"
              id="programsUrl"
              name="programsUrl"
              type="url"
              value={newSponsor.programsUrl}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Careers Url"
              id="careersUrl"
              name="careersUrl"
              type="url"
              value={newSponsor.careersUrl}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Social"
              id="socialUrl"
              name="socialUrl"
              type="url"
              value={newSponsor.socialUrl}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Solution Form"
              id="solutionFormUrl"
              name="solutionFormUrl"
              type="url"
              value={newSponsor.solutionFormUrl}
              onChange={updateObjData}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              value="newSponsor"
            >
              Create Sponsor
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
