import {IconButton, TextField, Tooltip} from '@material-ui/core';
import {AssignmentReturnOutlined as AssignmentReturnOutlinedIcon} from '@material-ui/icons';
import Axios from 'axios';
import MaterialTable from 'material-table';
import React, {Fragment, useEffect, useState} from 'react';
import PopupForm from '../utils/PopupForm';
import {tableIcons} from '../../styles/tableIcons';
import AddTeacherClassroomForm from '../AddTeacherClassroomForm';
import TeacherUpdateClassroomForm from '../TeacherUpdateClassroomForm';

export function TeacherClassroomsTable({user}) {
  const [classrooms, setClassrooms] = useState([]);
  const [classroom, setClassroom] = useState({});

  const columns = [
    {title: 'Name', field: 'name'},
    {
      title: 'Student Signup Link',
      field: 'signupCode',
      render: (rowData) => {
        const baseUri = document.baseURI.split('/').slice(0, 3).join('/');
        const signupCode = rowData.signupCode ? rowData.signupCode : '';
        const classroomSignupLink = `${baseUri}/${user.provider}/${signupCode}`;
        return (
          <Fragment>
            <TextField
              size="small"
              disabled
              name="signupCode"
              label="Copy and share"
              variant="outlined"
              value={classroomSignupLink}
            />
            <Tooltip title="Copy signup link">
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(classroomSignupLink)
                }
              >
                <AssignmentReturnOutlinedIcon style={{color: '#19cccc'}} />
              </IconButton>
            </Tooltip>
          </Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    const getClassrooms = async () => {
      const result = await Axios.get('/api/user/me/classrooms');

      setClassrooms(result.data);
    };
    getClassrooms();
  }, []);

  return (
    <MaterialTable
      actions={[
        {
          icon: () => (
            <PopupForm
              component={AddTeacherClassroomForm}
              title="Add Classroom"
              user={user}
            />
          ),
          tooltip: 'Create Classroom',
          isFreeAction: true,
        },
        {
          icon: () => (
            <PopupForm
              component={TeacherUpdateClassroomForm}
              classroom={classroom}
              title="Update Classroom"
            />
          ),
          tooltip: 'update sponsor',
          onClick: (event, rowData) => setClassroom(rowData),
        },
      ]}
      options={{
        actionsColumnIndex: -1,
      }}
      title={'Classrooms'}
      columns={columns}
      data={classrooms}
      icons={tableIcons}
    />
  );
}
