import {Container, makeStyles} from '@material-ui/core';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import AdminUserTable from '../AdminUsersTable/AdminUsersTable';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '50px',
  },
}));

export function AdminUserPageContent() {
  const classes = useStyles();

  const [users, setUsers] = useState([]);

  // Gets All users
  useEffect(() => {
    const getAllUsers = async () => {
      const users = await axios.get('/api/user');
      setUsers(users.data);
    };
    getAllUsers();
  }, [setUsers]);

  return (
    <Container component="div" classes={{root: classes.root}}>
      <AdminUserTable users={users} />
    </Container>
  );
}
