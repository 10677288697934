import {
  Container,
  CssBaseline,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import React, {useState} from 'react';
import {GoogleLogin} from 'react-google-login';
import {useHistory, useRouteMatch} from 'react-router-dom';
import Image from '../../../assets/meterhero-logo-yellow.png';
import {saveAuthToken} from '../../../utils/auth';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#263238',
    color: '#F6F9F3',
    height: '100vh',
    paddingTop: theme.spacing(3),
  },

  paper: {
    maxWidth: '350px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  TOSCheckbox: {
    color: "#F6F9F3",
    '& span': {
      color: "#F6F9F3",
    },
  },

  Gbtn: {textAlign: 'center'},

  googleBtn: {
    width: '100%',
    '& span': {
      margin: 'auto',
    },
  },
}));

export default function GoogleTeacherSignup() {
  const {root, paper, TOSCheckbox, Gbtn, googleBtn} = useStyles();

  let history = useHistory();

  const [error, setError] = useState(false);
  const [acceptTOS, setAcceptTOS] = useState(false);

  const handleAcceptTOSChange = () => {
    setAcceptTOS(!acceptTOS);
  }

  const {params} = useRouteMatch('/googleteacher/:schoolCode');

  const googleLoginSuccess = async (googleResponse) => {
    let authResponse = {
      id_token: '',
      schoolCode: params.schoolCode,
      TOSAgreement: {
        agree: acceptTOS,
      },
    };

    try {
      authResponse.id_token = await googleResponse.getAuthResponse().id_token;
    } catch (error) {
      setError(true);
    }

    try {
      let serverResponse = await axios.post('/api/googleteacher', authResponse);
      if (serverResponse.status === 200) {
        saveAuthToken(serverResponse.data.token);
        history.push('/');
      }
    } catch (error) {
      if (error.response.status === 400) {
        setError(true);
      }
    } 
  };

  const googleLoginFailure = (googleResponse) => {
    console.error(googleResponse);
  };

  let errorMessage;
  if (error) {
    errorMessage = (
      <Snackbar open={true}>
        <Alert severity="error">
          Signup code invalid, email STEMhero: support@STEMhero.com or call 414-540-8788
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Container
      component="main"
      className={root}
      maxWidth="xl"
      disableGutters={true}
    >
      <CssBaseline />

      <div className={paper}>
        <div
          style={{
            height: '50vh',
            width: '100%',
          }}
        >
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            src={Image}
            alt="Stem Hero company logo"
          />
        </div>

        <Grid
          container
          classes={{
            root: Gbtn,
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            <GoogleLogin
              className={googleBtn}
              buttonText="Google Teacher Signin"
              theme="dark"
              onSuccess={googleLoginSuccess}
              onFailure={googleLoginFailure}
              cookiePolicy={'single_host_origin'}
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              disabled={!acceptTOS}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox className={TOSCheckbox} />}
                onChange={handleAcceptTOSChange}
                label={
                  <div>
                    <span>I accept the MeterHero </span>
                    <Link href="https://meterhero.com/legal" target="_blank">
                      terms of use and privacy policy
                    </Link>
                  </div>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
        <div>
          {errorMessage}
        </div>
      </div>
    </Container>
  );
}
