import {Grid, MenuItem, makeStyles, Select} from '@material-ui/core';
import Axios from 'axios';
import React, {useEffect, useState} from 'react';
// import {useStyles, refreshPage} from './DashboardSideBar';

function refreshPage() {
  window.location.reload();
}

const useStyles = makeStyles((theme) => ({
  selectIcon: {
    color: 'white',
  },
  selectRoot: {
    '&:before': {
      borderColor: theme.palette.stemhero.blue,
    },
    '&:after': {
      borderColor: theme.palette.stemhero.blue,
    },

    '&:hover:not(.Mui-disabled):before': {
      borderColor: theme.palette.stemhero.blue,
    },
  },
}));

export function TeacherClassroomSelectInput({user}) {
  const classes = useStyles();

  const [teacherClassrooms, setTeacherClassrooms] = useState([]);

  const {classroom, ...cleanUser} = user;

  const foo = {...cleanUser, classroom: classroom._id};

  const [teacherClassroomId, setTeacherClassroomId] = useState({
    ...foo,
    classroomModel: classroom,
  });

  useEffect(() => {
    const handleSubmit = async (e) => {
      if (user.classroom._id === teacherClassroomId.classroom) {
        return;
      }
      const {classroomModel, ...data} = teacherClassroomId;
      try {
        await Axios.put('/api/user/me', data);

        refreshPage();
      } catch (error) {
        console.error(error);
      }
    };

    handleSubmit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherClassroomId]);

  useEffect(() => {
    const getClassRoomMeters = async () => {
      const result = await Axios.get('/api/user/me/classrooms');
      setTeacherClassrooms(result.data);
    };
    getClassRoomMeters();

    return () => setTeacherClassrooms(null);
  }, []);

  const updateState = (e) => {
    setTeacherClassroomId({
      ...teacherClassroomId,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Select
          className={classes.selectRoot}
          classes={{
            icon: classes.selectIcon,
          }}
          fullWidth
          style={{color: 'lightgrey'}}
          value={teacherClassroomId.classroom}
          name="classroom"
          onChange={updateState}
          inputProps={{
            name: 'classroom',
            id: 'age-native-simple',
          }}
        >
          {teacherClassrooms.length ? (
            teacherClassrooms.map((index) => {
              return (
                <MenuItem key={index.signupCode} value={index._id}>
                  {index.name}
                </MenuItem>
              );
            })
          ) : (
            <option aria-label="None" value="" />
          )}
        </Select>
      </Grid>
    </Grid>
  );
}
