import {makeStyles} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, {useState} from 'react';

import {AddBox} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: '300px',
  },
}));

export default function PopupForm({component: Component, title, ...rest}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AddBox variant="outlined" color="primary" onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{root: classes.root}}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Component setOpen={setOpen} {...rest} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
