import {createTheme} from '@material-ui/core/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#00BCD4',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#FF3344',
    },
    background: {
      default: '#fff',
      drawer: '#263238',
    },
    stemhero: {
      red: '#FF3344',
      darkBlue: '#006DAA',
      blue: '#19CCCC',
      yellow: '#F9FA34',
    },
    meterTypeColor: (meter) => {
      if (meter.type === 'gas') {
        return theme.palette.stemhero.blue;
      } else if (meter.type === 'water') {
        return theme.palette.stemhero.darkBlue;
      } else if (meter.type === 'electric') {
        return theme.palette.stemhero.red;
      } else {
        return 'lightgrey';
      }
    },
  },
});

export default theme;
