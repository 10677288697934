function getConversionRateActionText(meterUnit, formUnit) {
  let toConvertCubicFeetToCubicMeters =
    meterUnit === 'cubic-feet' && formUnit === 'cubic-meters';
  let toConvertCubicMetersToCubicFeet =
    meterUnit === 'cubic-meters' && formUnit === 'cubic-feet';

  const actionText = toConvertCubicFeetToCubicMeters
    ? `Multiply ${formUnit} by 35.32 to convert`
    : toConvertCubicMetersToCubicFeet
    ? `Divide ${formUnit} by 35.32 to convert`
    : meterUnit === 'gallons' && formUnit === 'liters'
    ? `Divide ${formUnit} by 3.79 to convert`
    : meterUnit === 'gallons' && formUnit === 'cubic-meters'
    ? `Multiply ${formUnit} by 264.17 to convert`
    : meterUnit === 'gallons' && formUnit === 'cubic-feet'
    ? `Multiply ${formUnit} by 7.42 to convert`
    : meterUnit === 'liters' && formUnit === 'gallons'
    ? `Multiply ${formUnit} by 3.79 to convert`
    : meterUnit === 'liters' && formUnit === 'cubic-meters'
    ? `Multiply ${formUnit} by 1000 to convert`
    : meterUnit === 'liters' && formUnit === 'cubic-feet'
    ? `Multiply ${formUnit} by 28.32 to convert`
    : meterUnit === 'cubic-meters' && formUnit === 'cubic-feet'
    ? `Divide ${formUnit} by 35.32 to convert`
    : meterUnit === 'cubic-meters' && formUnit === 'liters'
    ? `Divide ${formUnit} by 1000 to convert`
    : meterUnit === 'cubic-meters' && formUnit === 'gallons'
    ? `Divide ${formUnit} by 264.17 to convert`
    : meterUnit === 'cubic-feet' && formUnit === 'gallons'
    ? `Divide ${formUnit} by 7.42 to convert`
    : meterUnit === 'cubic-feet' && formUnit === 'liters'
    ? `Divide ${formUnit} by 28.32 to convert`
    : meterUnit === 'cubic-feet' && formUnit === 'cubic-meters'
    ? `Multiply ${formUnit} by 35.32 to convert`
    : 'Multiply or Divide to make sure you have the correct input value';
  return actionText;
}
function meterUnitConversationRateAction(meterUnit, formUnit, formUnitValue) {
  if (meterUnit === 'cubic-feet' && formUnit === 'cubic-meters') {
    let num = formUnitValue * 35.32;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'cubic-meters' && formUnit === 'cubic-feet') {
    let num = formUnitValue / 35.32;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'gallons' && formUnit === 'liters') {
    let num = formUnitValue / 3.79;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'gallons' && formUnit === 'cubic-meters') {
    let num = formUnitValue * 264.17;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'gallons' && formUnit === 'cubic-feet') {
    let num = formUnitValue * 7.42;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'liters' && formUnit === 'gallons') {
    let num = formUnitValue * 3.79;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'liters' && formUnit === 'cubic-meters') {
    let num = formUnitValue * 1000;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'liters' && formUnit === 'cubic-feet') {
    let num = formUnitValue * 28.32;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'cubic-meters' && formUnit === 'cubic-feet') {
    let num = formUnitValue / 35.32;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'cubic-meters' && formUnit === 'liters') {
    let num = formUnitValue / 1000;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'cubic-meters' && formUnit === 'gallons') {
    let num = formUnitValue / 264.17;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'cubic-feet' && formUnit === 'gallons') {
    let num = formUnitValue / 7.42;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'cubic-feet' && formUnit === 'liters') {
    let num = formUnitValue / 28.32;
    return Number.parseFloat(num).toFixed(2);
  } else if (meterUnit === 'cubic-feet' && formUnit === 'cubic-meters') {
    let num = formUnitValue * 35.32;
    return Number.parseFloat(num).toFixed(2);
  } else {
    return formUnitValue;
  }
}
function getMeterUnitTypes(meter) {
  const electricUnitType = [
    {
      value: 'kWh',
      label: 'Kilowatt-hour (kWh)',
    },
  ];
  const waterUnitType = [
    {
      value: 'gallons',
      label: 'Gallons',
    },
    {
      value: 'liters',
      label: 'Liters',
    },
    {
      value: 'cubic-meters',
      label: 'Cubic Meters',
    },
    {
      value: 'cubic-feet',
      label: 'Cubic Feet',
    },
  ];
  const gasUnitType = [
    {
      value: 'cubic-meters',
      label: 'Cubic Meters',
    },
    {
      value: 'cubic-feet',
      label: 'Cubic Feet',
    },
  ];

  const meterUnitType =
    meter.type === 'electric'
      ? electricUnitType
      : meter.type === 'water'
      ? waterUnitType
      : meter.type === 'gas'
      ? gasUnitType
      : [];
  return meterUnitType;
}

export {
  getConversionRateActionText,
  meterUnitConversationRateAction,
  getMeterUnitTypes,
};
