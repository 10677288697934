import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useRouteMatch} from 'react-router-dom';

export function AdminUserInfoCard() {
  const [user, setUser] = useState({});
  const {params} = useRouteMatch('/admin/users/:id');

  const {name, email, role, _id} = user;

  useEffect(() => {
    const getUser = async () => {
      const user = await axios.get(`/api/user/${params.id}`);
      setUser(user.data);
    };
    getUser();

    return () => {
      setUser({});
    };
  }, [setUser, params.id]);
  return (
    <div>
      <h1>User Info!</h1>
      <h3>{`Name: ${name}`}</h3>
      <h3>{`Role: ${role}`}</h3>
      <h3>{`Email: ${email}`}</h3>
      <h3>{`ID: ${_id}`}</h3>
    </div>
  );
}
