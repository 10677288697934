import {
  Container,
  CssBaseline,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import React, {useState} from 'react';
import {GoogleLogin} from 'react-google-login';
import {useHistory} from 'react-router-dom';
import Image from '../../../assets/meterhero-logo-yellow.png';
import DarkBgTextField from '../../../components/DarkBgTextField';
import {saveAuthToken} from '../../../utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#263238',
    color: '#F6F9F3',
    height: '100vh',
    paddingTop: theme.spacing(3),
  },

  paper: {
    maxWidth: '350px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  TOSCheckbox: {
    color: "#F6F9F3",
    '& span': {
      color: "#F6F9F3",
    },
  },

  Gbtn: {textAlign: 'center'},

  googleBtn: {
    width: '100%',
    '& span': {
      margin: 'auto',
    },
  },
}));

export default function GoogleStudentSignup() {
  const {root, paper, TOSCheckbox, Gbtn, googleBtn} = useStyles();

  let history = useHistory();

  const [classroomCode, setClassroomCode] = useState({
    classroomCode: '',
  });
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(false);
  const [acceptTOS, setAcceptTOS] = useState(false);

  const handleOnChange = (event) => {
    setClassroomCode(event.target.value);
  };

  const handleAcceptTOSChange = () => {
    setAcceptTOS(!acceptTOS);
  }

  const googleLoginSuccess = async (googleResponse) => {
    if (!classroomCode) {
      setError(true);
      setErrors({msg: 'Classroom code required'});
      return;
    }

    let authResponse = {
      id_token: '',
      classroomCode: classroomCode,
      TOSAgreement: {
        agree: acceptTOS,
      },
    };

    try {
      authResponse.id_token = await googleResponse.getAuthResponse().id_token;
    } catch (error) {
      setError(true);
      setErrors({msg: 'Problem authenticating with Google'});
    }

    try {
      let serverResponse = await axios.post('/api/google', authResponse);
      if (serverResponse.status === 200) {
        saveAuthToken(serverResponse.data.token);
        history.push('/');
      }
    } catch (error) {
      if (error.response.status === 400) {
        setError(true);
        setErrors({msg: error.response.data});
      }
    }
  };

  const googleLoginFailure = (googleResponse) => {
    console.error(googleResponse);
  };

  return (
    <Container
      component="main"
      className={root}
      maxWidth="xl"
      disableGutters={true}
    >
      <CssBaseline />

      <div className={paper}>
        <div
          style={{
            height: '50vh',
            width: '100%',
          }}
        >
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            src={Image}
            alt="Stem Hero company logo"
          />
        </div>
        <Grid
          container
          classes={{
            root: Gbtn,
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            <DarkBgTextField
              label="Classroom code"
              placeholder="Enter your classrooms code"
              name="classroomCode"
              type="text"
              onChange={handleOnChange}
              required
              error={error}
              helperText={errors && errors.msg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleLogin
              className={googleBtn}
              buttonText="Google Student Signup"
              theme="dark"
              onSuccess={googleLoginSuccess}
              onFailure={googleLoginFailure}
              cookiePolicy={'single_host_origin'}
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              disabled={!acceptTOS}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox className={TOSCheckbox} />}
                onChange={handleAcceptTOSChange}
                label={
                  <div>
                    <span>I accept the MeterHero </span>
                    <Link href="https://meterhero.com/legal" target="_blank">
                      terms of use and privacy policy
                    </Link>
                  </div>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
