import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core';
import Axios from 'axios';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

const useStyle = makeStyles(({spacing}) => ({
  root: {
    marginTop: spacing(2),
    marginBottom: spacing(4),
    padding: '32px 16px',
  },
}));

export const AddTeacherClassroomForm = ({user = {}}) => {
  const classes = useStyle();
  const [formData, setFormData] = useState({
    name: '',
    teacher: user._id,
    school: user.school,
  });

  let history = useHistory();

  const updateFormData = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const addClassroom = async (event) => {
    event.preventDefault();
    try {
      await Axios.post('/api/classroom', formData);

      history.go(0).push('/teacher/classrooms');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container
      component={Paper}
      elevation={0}
      maxWidth="sm"
      className={classes.root}
    >
      <form onSubmit={addClassroom}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              id="name"
              name="name"
              value={formData.name}
              onChange={updateFormData}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              value="formData"
            >
              Add Classroom
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
