import React from 'react';
import SideBarMeterListItem from '../SideBarMeterListItem';

export function MeterListItems({metersArr, activeItem, setActiveItem}) {
  return metersArr.map((meter) => (
    <SideBarMeterListItem
      key={meter._id}
      meter={meter}
      activeItem={activeItem}
      setActiveItem={setActiveItem}
    />
  ));
}
