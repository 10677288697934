import {Button, MenuItem, TextField} from '@material-ui/core';
import {CheckCircle, HighlightOff, Help, ThumbUp} from '@material-ui/icons';
import Axios from 'axios';
import React, {Fragment, useState} from 'react';
import {getMeterUnitTypes} from '../stepHelperFunction';
import {useStepsStyles} from '../useStepsStyles';

export function SetUintTypeComponent({
  meter,
  setMeter,
  setActiveStep,
  isMeterOwner,
}) {
  const classes = useStepsStyles(meter);
  const [updatedUnitType, setUpdatedUnitType] = useState({
    unit: meter.unit,
  });

  const [onSuccess, setOnSuccess] = useState(false);

  const meterUnitType = getMeterUnitTypes(meter);

  const saveMeterUnit = async (event) => {
    event.preventDefault();
    let updatedMeter = {
      ...meter,
      currentStep: 4,
      ...updatedUnitType,
    };
    try {
      const result = await Axios.post(`/api/meter/${meter._id}`, updatedMeter);

      setMeter(result.data);

      if (result.status === 200) {
        setOnSuccess(true);
      }
      setTimeout(() => {
        setActiveStep((prevState) => prevState + 1);
      }, 600);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    setUpdatedUnitType({
      ...updatedUnitType,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Fragment>
      <form onSubmit={saveMeterUnit}>
        <TextField
          style={{minWidth: '135px'}}
          placeholder="My estimated Usage(kWh/day)"
          select
          id="unit"
          name="unit"
          value={updatedUnitType.unit}
          onChange={handleInputChange}
        >
          {meterUnitType.map((m) => (
            <MenuItem key={m.value} value={m.value}>
              {m.label}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <br />
        <Button
          type="submit"
          value="est"
          className={classes.root}
          variant="outlined"
          disabled={!isMeterOwner}
        >
          {!isMeterOwner ? (
            <HighlightOff style={{color: 'grey'}} />
          ) : onSuccess ? (
            <ThumbUp style={{color: 'white'}} />
          ) : (
            <CheckCircle style={{color: 'white'}} />
          )}
        </Button>
        <Button
          className={classes.root}
          variant="outlined"
          component="a"
          href="https://meterhero.com/source"
          target="_blank"
          rel="noopener"
        >
          <Help style={{color: 'white'}} />
        </Button>
      </form>
    </Fragment>
  );
}
