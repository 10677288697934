import {
  Button,
  Container,
  FormControl,
  Grid,
  // InputLabel,
  makeStyles,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

const useStyle = makeStyles(({spacing}) => ({
  root: {
    marginTop: spacing(2),
    marginBottom: spacing(4),
    padding: '32px 16px',
  },
  formControl: {
    margin: spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: spacing(2),
  },
  formControlRoot: {
    width: '100%',
  },
}));

export const AdminUpdateSchoolForm = ({school}) => {
  const classes = useStyle();
  const [sponsors, setSponsors] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    sponsor: '',
  });

  let history = useHistory();

  useEffect(() => {
    const getSponsors = async () => {
      try {
        const result = await axios.get('/api/sponsor');
        setSponsors(result.data);
      } catch (error) {
        console.error(error);
      }
    };
    setFormData(school);
    getSponsors();
  }, [school]);

  const updateFormData = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const updateSchool = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`/api/school/${school._id}`, formData);

      history.go(0).push('/admin/schools');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container
      component={Paper}
      elevation={0}
      maxWidth="sm"
      className={classes.root}
    >
      <form onSubmit={updateSchool}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="School Name"
              id="name"
              name="name"
              value={formData.name}
              onChange={updateFormData}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              classes={{root: classes.formControlRoot}}
              className={classes.formControl}
            >
              <Select
                native
                value={formData.sponsor}
                onChange={updateFormData}
                inputProps={{
                  name: 'sponsor',
                  id: 'age-native-simple',
                }}
              >
                <option aria-label="None" value="" />
                {sponsors.length ? (
                  sponsors.map((sponsor) => (
                    <option value={sponsor._id}>{sponsor.name}</option>
                  ))
                ) : (
                  <option aria-label="None" value="" />
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              value="formData"
            >
              Update School
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
