import {
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import React, {useState} from 'react';
import {GoogleLogin} from 'react-google-login';
import MicrosoftLogin from "react-microsoft-login";
import {Link, useHistory} from 'react-router-dom';
import Image from '../../../assets/meterhero-logo-yellow.png';
import DarkBgTextField from '../../../components/DarkBgTextField';
import {saveAuthToken} from '../../../utils/auth';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#263238',
    height: '100vh',
    paddingTop: theme.spacing(3),
  },
  form: {
    marginTop: '-1.7rem',
  },
  paper: {
    maxWidth: '350px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  Gbtn: {textAlign: 'center'},
  Mbtn: {textAlign: 'center'},
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login() {
  const {root, paper, submit, form, Gbtn, Mbtn} = useStyles();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(false);
  const [googleError, setGoogleError] = useState(false);
  const [microsoftError, setMicrosoftError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {email, password} = formData;

  let history = useHistory();

  const updateFormData = (event) => {
    if (errors) setErrors([]);
    if (error === true) setError(false);
    setFormData({...formData, [event.target.name]: event.target.value});
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginUser = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/signin', formData);

      saveAuthToken(response.data.token);
      history.push('/');
    } catch (err) {
      if (err) {
        setError(true);
        setErrors({msg: "( Something doesn't look right )"});
      }
    }
  };

  const googleLoginSuccess = async (googleResponse) => {
    let authResponse = {id_token: ''};
    try {
      authResponse.id_token = await googleResponse.getAuthResponse().id_token;
      let serverResponse = await axios.post('/api/google', authResponse);
      if (serverResponse.status === 200) {
        saveAuthToken(serverResponse.data.token);
        history.push('/');
      } else {
        setGoogleError(true);
      }
    } catch (err) {
      setGoogleError(true);
    }
  };

  const googleLoginFailure = (googleResponse) => {
    console.error(googleResponse);
  };

  const authHandler = async (error, microsoftToken, msal) => {
    if (error) {
      console.error("auth error: ", error);
      return;
    }

    try {
      let serverResponse = await axios.post('http://localhost:3000/api/microsoft', {}, {
        headers: {
          Authorization: 'Bearer ' + microsoftToken.idToken.rawIdToken //the token is a variable which holds the token
        },
      });
      if (serverResponse.status === 200) {
        console.log('success');
        saveAuthToken(serverResponse.data.token);
        history.push('/');
      }
    } catch (e) {
      console.log(e);
      setMicrosoftError(true);
      // Workaround to prevent infinite loop error in react-microsoft-login component
      // https://github.com/alexandrtovmach/react-microsoft-login/issues/84
      const sessionStorage = window.sessionStorage;
      sessionStorage.clear();
      return;
    }
  };

  let errorMessage;
  if (googleError || microsoftError) {
    errorMessage = (
      <Snackbar open={true}>
        <Alert severity="error">
          <a href="https://teachers.meterhero.com/">
            Create an account
          </a>
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Container
      component="main"
      className={root}
      maxWidth="xl"
      disableGutters={true}
    >
      <CssBaseline />

      <div className={paper}>
        <div
          style={{
            height: '50vh',
            width: '100%',
          }}
        >
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            src={Image}
            alt="Stem Hero company logo"
          />
        </div>
        <form onSubmit={loginUser} className={form}>
          <DarkBgTextField
            label="Email address"
            placeholder="Enter email address"
            id="email"
            name="email"
            type="text"
            value={email}
            error={error}
            onChange={updateFormData}
            required
            fullWidth
          />

          <DarkBgTextField
            label="Password"
            placeholder="Please enter your password"
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            error={error}
            helperText={errors && errors.msg}
            inputProps={{htmlFor: 'password'}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <Visibility style={{color: 'white'}} />
                    ) : (
                      <VisibilityOff style={{color: 'white'}} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={updateFormData}
            autoFocus={true}
            fullWidth
            required
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            value="Login"
            fullWidth
            className={submit}
          >
            Login Now
          </Button>
        </form>

        <Grid
          container
          classes={{
            root: Gbtn,
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            <GoogleLogin
              buttonText="Google Signin"
              theme="dark"
              onSuccess={googleLoginSuccess}
              onFailure={googleLoginFailure}
              cookiePolicy={'single_host_origin'}
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            />
          </Grid>
          <Grid item xs={12}>
            <MicrosoftLogin
              clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
              redirectUri={`${window.location.origin}/`}
              authCallback={authHandler}
              className={Mbtn}
            />
          </Grid>
        </Grid>
      </div>
      <div>{errorMessage}</div>
    </Container>
  );
}

export {Login};
