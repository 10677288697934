import {makeStyles} from '@material-ui/core';
import moment from 'moment';
import React from 'react';

const useStyle = makeStyles((theme) => ({
  customToolTip: {
    backgroundColor: 'rgba(0, 0, 0, 0.61)',
    padding: theme.spacing(1),
    borderRadius: '4px',
    color: 'white',
    minWidth: '140px',
    maxWidth: '190px',
    maxHeight: '160px',
  },
}));
export const CustomToolTip = (props) => {
  const {active, payload, meter} = props;

  const classes = useStyle();
  if (active) {
    return (
      <div className={classes.customToolTip}>
        {payload &&
          payload.map((p) => {
            return (
              <div key={p.value}>
                <p><strong>{moment(p.payload.time).format('ll')}</strong></p>
                <p>{moment(p.payload.time).format('LT')}</p>
                <p>{p.payload.yValue} {meter.unit}</p>
              </div>
            );
          })}
      </div>
    );
  }
  return null;
};
