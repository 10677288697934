import {
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Image from '../../../assets/meterhero-logo-yellow.png';
import DarkBgTextField from '../../../components/DarkBgTextField';
import {saveAuthToken} from '../../../utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#263238',
    minHeight: '100vh',
    height: '100%',
    paddingTop: theme.spacing(3),
  },
  form: {
    marginTop: '-3.0rem',
  },
  paper: {
    maxWidth: '350px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function EmailStudentSignupForm() {
  const {root, paper, form} = useStyles();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password2: '',
    classroomCode: '',
    TOSAgreement: {
      agree: true,
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(false);

  const {email, password, password2, classroomCode} = formData;

  let history = useHistory();

  const updateFormData = (event) => {
    if (errors) setErrors([]);
    if (error === true) setError(false);
    setFormData({...formData, [event.target.name]: event.target.value});
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const signupUser = async (event) => {
    event.preventDefault();
    if (email === '' || password === '' || !classroomCode) {
      setError(true);
      setErrors({msg: 'Invalid Field'});
    } else if (password.length < 6) {
      setError(true);
      setErrors({password: '( Choose a longer password )'});
    } else if (password !== password2) {
      setError(true);
      setErrors({password: '( Passwords must match!! )'});
    } else {
      try {
        const response = await axios.post('/api/signup', formData);
        saveAuthToken(response.data.token);
        history.push('/');
      } catch (error) {
        if (error.response.status === 400) {
          setError(true);
          setErrors({msg: error.response.data});
        }
      }
    }
  };

  return (
    <Container className={root} component="main" maxWidth="xl">
      <CssBaseline />

      <div className={paper}>
        <div
          style={{
            height: '50vh',
            width: '100%',
          }}
        >
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            src={Image}
            alt="Stem Hero company logo"
          />
        </div>
        <form className={form} onSubmit={signupUser}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DarkBgTextField
                label="Classroom Code"
                placeholder="Enter classroom code"
                name="classroomCode"
                id="classroomCode"
                type="text"
                value={classroomCode}
                error={error}
                helperText={errors && errors.msg}
                onChange={updateFormData}
                margin="dense"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DarkBgTextField
                label="Email Address"
                placeholder="Email Address"
                name="email"
                id="email"
                type="email"
                value={email}
                error={error}
                onChange={updateFormData}
                margin="none"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DarkBgTextField
                label="Enter Password"
                placeholder="Enter Password"
                name="password"
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                error={error}
                helperText={errors && errors.password}
                inputProps={{htmlFor: 'password'}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <Visibility style={{color: 'white'}} />
                        ) : (
                          <VisibilityOff style={{color: 'white'}} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={updateFormData}
                margin="none"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <DarkBgTextField
                label="Confirm your password"
                placeholder="Confirm your password"
                name="password2"
                id="password2"
                type={showPassword ? 'text' : 'password'}
                value={password2}
                error={error}
                inputProps={{htmlFor: 'password2'}}
                onChange={updateFormData}
                fullWidth
                required
              />
            </Grid>
          </Grid>

          <Button
            value="Signup"
            type="submit"
            variant="contained"
            color="primary"
            style={{
              marginTop: '10px',
            }}
            fullWidth
          >
            Signup
          </Button>

          <Button
            component={Link}
            to={'/login'}
            variant="outlined"
            color="primary"
            style={{
              marginTop: '10px',
            }}
            fullWidth
          >
            To Login Page
          </Button>
        </form>
      </div>
    </Container>
  );
}
