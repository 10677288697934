import React from 'react';
import MaterialTable from 'material-table';
import {tableIcons} from '../../styles/tableIcons';
import PropTypes from 'prop-types';

export default function AdminUsersTable({users}) {
  const columns = [
    {title: 'Email', field: 'email'},
    {title: 'Role', field: 'role'},
    {title: 'Last Active', field: 'lastActive', type: 'datetime'},
  ];
  return (
    <MaterialTable
      icons={tableIcons}
      columns={columns}
      data={users}
      title={'Users'}
    />
  );
}

AdminUsersTable.prototype = {
  user: PropTypes.array.isRequired,
};
