import {Button, Grid, Typography, makeStyles, Link} from '@material-ui/core';
import {CheckCircle, Help, HighlightOff, ThumbUp} from '@material-ui/icons';
import Axios from 'axios';
import React, {useState} from 'react';

const useStyles = makeStyles(({palette}) => ({
  root: {
    marginRight: '4px',
    color: 'white',
    backgroundColor: palette.meterTypeColor,
    '&:hover': {
      opacity: '0.9',
      backgroundColor: palette.meterTypeColor,
    },
    '&:disabled': {
      opacity: '0.6',
      backgroundColor: 'lightgrey',
    },
  },
  container: {
    marginTop: 10,
  },
}));

export function CollectBaseLineComponent({
  meter,
  setMeter,
  setActiveStep,
  disableDataCollectionBtn,
  isMeterOwner,
}) {
  const classes = useStyles(meter);
  const [onSuccess, setOnSuccess] = useState(false);

  const hasSufficientReadings =
    meter.readings.filter((r) => !r.deleted).length >= 3;

  const isDisabled =
    !isMeterOwner || (!hasSufficientReadings && !disableDataCollectionBtn);

  const handleClick = async () => {
    try {
      let updateMeter = {
        ...meter,
        currentStep: 5,
      };

      const result = await Axios.post(`/api/meter/${meter._id}`, updateMeter);

      setMeter(result.data);
      if (result.status === 200) {
        setOnSuccess(true);
      }
      setTimeout(() => {
        setActiveStep((prevState) => prevState + 1);
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid className={classes.container} container>
        <Grid item>
          <Button
            type="submit"
            value="updatedMeter"
            className={classes.root}
            variant="outlined"
            disabled={isDisabled}
            onClick={handleClick}
          >
            {onSuccess ? (
              <ThumbUp style={{color: 'white'}} />
            ) : isDisabled ? (
              <HighlightOff style={{color: 'grey'}} />
            ) : (
              <CheckCircle style={{color: 'white'}} />
            )}
          </Button>
        </Grid>

        <Grid item>
          <Button
            className={classes.root}
            variant="outlined"
            component="a"
            href="https://meterhero.com/collect"
            target="_blank"
            rel="noopener"
          >
            <Help style={{color: 'white'}} />
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {isDisabled && (
            <Typography color="error">Collect at least 3 readings</Typography>
          )}
          {disableDataCollectionBtn && (
            <Typography color="error">Log into your utility portal and view the past 7 days of usage.</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
