import Axios from 'axios';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  CloudUpload as FinalProjectUploadIcon,
  CompareArrows as CollaborateIcon,
  ExploreOutlined as TeacherResourcesIcon,
  MonochromePhotosOutlined as VerifyIcon,
  MoreVert as NavIcon,
  PowerSettingsNew as LogoutIcon,
  Settings as SettingsIcon,
  SupervisorAccountOutlined as SupervisorAccountIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

export const useStyles = makeStyles((theme) => ({
  NavIcon: {
    color: theme.palette.stemhero.blue,
  },
}));

export function SideBarThreeDotNavMenu({user}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userSchool, setUserSchool] = React.useState({});
  const [userSponsor, setUserSponsor] = React.useState({});

  React.useEffect(() => {
    if (!user.school) return;
    const getUserSponsor = async () => {
      const userSchool = await Axios.get(`/api/school/${user.school}`);
      const userSponsor = await Axios.get(
        `/api/sponsor/${userSchool.data.sponsor}`,
      );
      setUserSponsor(userSponsor.data);
    };
    getUserSponsor();
  }, [user.school]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.clear();
    // Workaround to prevent infinite loop error in react-microsoft-login component
    // https://github.com/alexandrtovmach/react-microsoft-login/issues/84
    const sessionStorage = window.sessionStorage;
    sessionStorage.clear();
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
      >
        <NavIcon />
      </IconButton>
      {user && user.role === 'admin' ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem button component={Link} to={'/admin'}>
            <ListItemIcon>
              <SupervisorAccountIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Admin Dashboard" />
          </MenuItem>
          <MenuItem
            button
            component="a"
            href="https://meterhero.com/verify"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
          >
            <ListItemIcon>
              <VerifyIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Verify" />
          </MenuItem>
          <MenuItem
            button
            component="a"
            href="https://meterhero.com/collaborate"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
          >
            <ListItemIcon>
              <CollaborateIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Collaborate" />
          </MenuItem>
          <MenuItem
            button
            component="a"
            href="https://meterhero.com/guide"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
          >
            <ListItemIcon>
              <TeacherResourcesIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Teacher resources" />
          </MenuItem>
          <MenuItem button onClick={logout} component={Link} to={'/'}>
            <ListItemIcon>
              <LogoutIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      ) : user && user.role === 'teacher' ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            button
            onClick={handleClose}
            component={Link}
            to={'/teacher/classrooms'}
          >
            <ListItemIcon>
              <SettingsIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </MenuItem>
          <MenuItem
            button
            component="a"
            href="https://meterhero.com/verify"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
          >
            <ListItemIcon>
              <VerifyIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Verify" />
          </MenuItem>
          <MenuItem
            button
            component="a"
            href="https://meterhero.com/collaborate"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
          >
            <ListItemIcon>
              <CollaborateIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Collaborate" />
          </MenuItem>
          <MenuItem
            button
            component="a"
            href="https://meterhero.com/guide"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
          >
            <ListItemIcon>
              <TeacherResourcesIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Teacher resources" />
          </MenuItem>
          {(userSponsor && userSponsor.finalProjectUrl) ? ( 
            <MenuItem
              button
              component="a"
              href={userSponsor.finalProjectUrl}
              target="_blank"
              rel="noopener"
              onClick={handleClose}
            >
              <ListItemIcon>
                <FinalProjectUploadIcon style={{color: 'black'}} />
              </ListItemIcon>
              <ListItemText primary="Upload final projects" />
            </MenuItem>
            ) : (null)
          }
          <MenuItem button onClick={logout} component={Link} to={'/'}>
            <ListItemIcon>
              <LogoutIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            button
            component="a"
            href="https://meterhero.com/verify"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
          >
            <ListItemIcon>
              <VerifyIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Verify" />
          </MenuItem>
          <MenuItem
            button
            component="a"
            href="https://meterhero.com/collaborate"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
          >
            <ListItemIcon>
              <CollaborateIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Collaborate" />
          </MenuItem>
          <MenuItem
            button
            component="a"
            href="https://meterhero.com/guide"
            target="_blank"
            rel="noopener"
            onClick={handleClose}
          >
            <ListItemIcon>
              <TeacherResourcesIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Teacher resources" />
          </MenuItem>
          <MenuItem button onClick={logout} component={Link} to={'/'}>
            <ListItemIcon>
              <LogoutIcon style={{color: 'black'}} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}

SideBarThreeDotNavMenu.prototype = {
  user: PropTypes.object.isRequired,
};
