import {ThemeProvider} from '@material-ui/core';
import React, {Fragment} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {PrivateRoute} from './hoc/PrivateRoute';
import AdminDashboard from './pages/AdminDashboard';
import GoogleStudentSignup from './pages/authentication/google_signup/GoogleStudentSignup';
import GoogleStudentSignupForm from './pages/authentication/google_signup/GoogleStudentSignupForm';
import GoogleTeacherSignup from './pages/authentication/google_signup/GoogleTeacherSignup';
import GoogleTeacherSignupForm from './pages/authentication/google_signup/GoogleTeacherSignupForm';
import MicrosoftStudentSignup from './pages/authentication/microsoft_signup/MicrosoftStudentSignup';
import MicrosoftStudentSignupForm from './pages/authentication/microsoft_signup/MicrosoftStudentSignupForm';
import MicrosoftTeacherSignup from './pages/authentication/microsoft_signup/MicrosoftTeacherSignup';
import MicrosoftTeacherSignupForm from './pages/authentication/microsoft_signup/MicrosoftTeacherSignupForm';
import {Login} from './pages/authentication/login/Login';
import Signin from './pages/authentication/login/Signin';
import EmailStudentSignupForm from './pages/authentication/signup/EmailStudentSignupFrom';
import EmailStudentSignupFormWithUrlCode from './pages/authentication/signup/EmailStudentSignupFromWithUrlCode';
import EmailTeacherSignupForm from './pages/authentication/signup/EmailTeacherSignupFrom';
import EmailTeacherSignupFormWithUrlCode from './pages/authentication/signup/EmailTeacherSignupFromWithUrlCode';
import Dashboard from './pages/Dashboard';
import TeacherDashboard from './pages/TeacherDashboard';
import theme from './styles/theme';
import {checkAuth} from './utils/auth';

checkAuth();
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Fragment>
          <Switch>
            {/*
              this is a route for the user to login with email and password
            */}
            <Route exact path="/login" component={Login} />

            {/*
              this is a route for the user to login Googles Authentication
            */}
            <Route exact path="/signin" component={Signin} />

            {/*
              this is a route for the Student to sign up with
              Googles Authentication and entering their classroom code 
              into a text field.
            */}
            <Route exact path="/google" component={GoogleStudentSignupForm} />

            {/*
              this is a route for the Student to sign up with
              Googles Authentication and classroom code 
              in the URL.. This should be the most use case for 
              student signup.
            */}
            <Route
              path="/google/:classroomCode"
              component={GoogleStudentSignup}
            />

            {/*
              this is a route for the Teacher to sign up with
              Googles Authentication and entering their classroom code 
              into a text field.
            */}
            <Route
              exact
              path="/googleteacher"
              component={GoogleTeacherSignupForm}
            />

            {/*
              this is a route for the Teacher to sign up with
              Googles Authentication and classroom code 
              in the URL.. This should be the most use case for 
              teacher signup.
            */}
            <Route
              path="/googleteacher/:schoolCode"
              component={GoogleTeacherSignup}
            />

            {/*
              this is a route for the Student to sign up with
              Microsoft Authentication and entering their classroom code 
              into a text field.
            */}
            <Route exact path="/microsoft" component={MicrosoftStudentSignupForm} />

            {/*
              this is a route for the Student to sign up with
              Microsoft Authentication and classroom code 
              in the URL.. This should be the most use case for 
              student signup.
            */}
            <Route
              path="/microsoft/:classroomCode"
              component={MicrosoftStudentSignup}
            />

            {/*
              this is a route for the Teacher to sign up with
              Microsoft Authentication and entering their classroom code 
              into a text field.
            */}
            <Route
              exact
              path="/microsoftteacher"
              component={MicrosoftTeacherSignupForm}
            />

            {/*
              this is a route for the Teacher to sign up with
              Microsoft Authentication and classroom code 
              in the URL.. This should be the most use case for 
              teacher signup.
            */}
            <Route
              path="/microsoftteacher/:schoolCode"
              component={MicrosoftTeacherSignup}
            />

            {/*
              this is a route for the Student to sign up with
              email, password, and entering their classroom code 
              into a text field.
            */}
            <Route exact path="/signup" component={EmailStudentSignupForm} />

            {/*
              this is a route for the Student to sign up with
              email, password, and their classroom code in the URL.
            */}
            <Route
              path="/signup/:classroomCode"
              component={EmailStudentSignupFormWithUrlCode}
            />

            {/*
              this is a route for the Teacher to sign up with
              email, password, and entering their classroom code 
              into a text field.
            */}
            <Route
              exact
              path="/signupteacher"
              component={EmailTeacherSignupForm}
            />

            {/*
              this is a route for the Teacher to sign up with
              email, password, and their classroom code in the URL.
            */}
            <Route
              path="/signupteacher/:schoolCode"
              component={EmailTeacherSignupFormWithUrlCode}
            />

            {/*
              All private routes after authentication fall back will be to login page
            */}
            <PrivateRoute path="/admin">
              <AdminDashboard />
            </PrivateRoute>

            <PrivateRoute path="/teacher">
              <TeacherDashboard />
            </PrivateRoute>

            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Fragment>
      </BrowserRouter>
    </ThemeProvider>
  );
}
