import {Container, InputAdornment} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import DarkBgTextField from '../DarkBgTextField';
import {TeacherClassroomSelectInput} from '../DashboardSideBar/TeacherClassroomSelectInput';

export default function SideBarTeacherInputs(
  user,
  setUser,
  text,
  onSearchChange,
) {
  return (
    <Container component="div">
      <div style={{marginTop: '10px', paddingTop: '10px'}}>
        <TeacherClassroomSelectInput user={user} setUser={setUser} />
      </div>
      <br />
      <DarkBgTextField
        ref={text}
        onChange={onSearchChange}
        InputProps={{
          style: {
            color: 'white',
          },
          startAdornment: (
            <InputAdornment position="start" style={{paddingLeft: '8px'}}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </Container>
  );
}
