import {
  Container,
  CssBaseline,
  Grid,
  makeStyles,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import React, {useState} from 'react';
import {GoogleLogin} from 'react-google-login';
import MicrosoftLogin from "react-microsoft-login";
import {useHistory} from 'react-router-dom';
import Image from '../../../assets/meterhero-logo-yellow.png';
import {saveAuthToken} from '../../../utils/auth';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#263238',
    height: '100vh',
    paddingTop: theme.spacing(3),
  },
  paper: {
    maxWidth: '350px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  Gbtn: {textAlign: 'center'},
  googleBtn: {
    width: '100%',
    '& span': {
      margin: 'auto',
    },
  },
  Mbtn: {textAlign: 'center'},
}));

export default function Signin() {
  const {root, paper, Gbtn, googleBtn, Mbtn} = useStyles();

  let history = useHistory();

  const [googleError, setGoogleError] = useState(false);
  const [microsoftError, setMicrosoftError] = useState(false);

  const googleLoginSuccess = async (googleResponse) => {
    let authResponse = {id_token: ''};
    try {
      authResponse.id_token = await googleResponse.getAuthResponse().id_token;
      let serverResponse = await axios.post('/api/google', authResponse);
      if (serverResponse.status === 200) {
        saveAuthToken(serverResponse.data.token);
        history.push('/');
      } else {
        setGoogleError(true);
      }
    } catch (err) {
      setGoogleError(true);
    }
  };

  const googleLoginFailure = (googleResponse) => {
    console.error(googleResponse);
  };

  const authHandler = async (error, microsoftToken, msal) => {
    if (error) {
      console.error("auth error: ", error);
      return;
    }

    try {
      let serverResponse = await axios.post('/api/microsoft', {}, {
        headers: {
          Authorization: 'Bearer ' + microsoftToken.idToken.rawIdToken //the token is a variable which holds the token
        },
      });
      if (serverResponse.status === 200) {
        console.log('success');
        saveAuthToken(serverResponse.data.token);
        history.push('/');
      }
    } catch (e) {
      console.log(e);
      setMicrosoftError(true);
      // Workaround to prevent infinite loop error in react-microsoft-login component
      // https://github.com/alexandrtovmach/react-microsoft-login/issues/84
      const sessionStorage = window.sessionStorage;
      sessionStorage.clear();
      return;
    }
  };

  let errorMessage;
  if (googleError || microsoftError) {
    errorMessage = (
      <Snackbar open={true}>
        <Alert severity="error">
          <a href="https://teachers.meterhero.com/">
            Create an account
          </a>
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Container
      component="main"
      className={root}
      maxWidth="xl"
      disableGutters={true}
    >
      <CssBaseline />

      <div className={paper}>
        <div
          style={{
            height: '50vh',
            width: '100%',
          }}
        >
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            src={Image}
            alt="Stem Hero company logo"
          />
        </div>

        <Grid
          container
          classes={{
            root: Gbtn,
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            <GoogleLogin
              className={googleBtn}
              buttonText="Google Signin"
              theme="dark"
              onSuccess={googleLoginSuccess}
              onFailure={googleLoginFailure}
              cookiePolicy={'single_host_origin'}
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            />
          </Grid>
          <Grid item xs={12}>
            <MicrosoftLogin
              clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
              redirectUri={`${window.location.origin}/`}
              authCallback={authHandler}
              className={Mbtn}
            />
          </Grid>
        </Grid>
      </div>
      <div>{errorMessage}</div>
    </Container>
  );
}
