import {Grid, ListItem, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import {Link} from 'react-router-dom';
import {MeterIcon} from './MeterIcon';

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px 16px',
    borderBottom: '1px solid grey',
    '&:hover': {
      backgroundColor: '#2c406080',
    },
    '&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover': {
      backgroundColor: '#5f69a48a',
    },
  },
  typographyItem: {
    marginBottom: '8px',
  },
}));

export function SideBarMeterListItem({meter, activeItem, setActiveItem}) {
  const {root, typographyItem} = useStyles();
  const {_id, name, unit, readings} = meter;
  let safeUnit = unit ? `Unit: ${unit}` : 'Unit:  - -';

  const handleItemClick = (e) => {
    setActiveItem(_id);
  };

  return (
    <ListItem
      classes={{
        root: root,
      }}
      button
      component={Link}
      to={`/${_id}`}
      key={_id}
      selected={activeItem === _id}
      onClick={handleItemClick}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs container direction="column">
          <Grid item classes={{root: typographyItem}} xs>
            <Typography variant="body2">
              {meter.username ? meter.username : 'Classmate'}
            </Typography>
          </Grid>
          <Grid item xs container direction="row">
            <Grid item container alignItems="center" direction="row" xs>
              <MeterIcon meter={meter} />
              <Typography variant="body2">{name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs container alignContent="flex-end" direction="column">
          <Grid item xs>
            <Typography gutterBottom variant="body2" /> {safeUnit}
            <Typography variant="body2" gutterBottom>
              {`Readings: ${
                readings.length > 0
                  ? readings.reduce((count, reading) => {
                      return reading.deleted ? count : count + 1;
                    }, 0)
                  : 0
              }`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}
