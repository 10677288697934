import React from 'react';
import {
  FlashOn as ElectricIcon,
  ErrorOutline as MeterErrorDefaultIcon,
  Opacity as WaterIcon,
  Whatshot as GasIcon,
} from '@material-ui/icons';
import theme from '../../styles/theme';

export function MeterIcon({meter}) {
  if (meter.type === 'gas') {
    return (
      <GasIcon
        style={{
          color: theme.palette.stemhero.blue,
          marginLeft: '-3px',
          marginRight: '10px',
          fontSize: 20,
        }}
      />
    );
  } else if (meter.type === 'electric') {
    return (
      <ElectricIcon
        style={{
          color: theme.palette.stemhero.red,
          marginLeft: '-3px',
          fontSize: 20,
          marginRight: '10px',
        }}
      />
    );
  } else if (meter.type === 'water') {
    return (
      <WaterIcon
        style={{
          color: theme.palette.stemhero.darkBlue,
          marginLeft: '-5px',
          fontSize: 20,
          marginRight: '10px',
        }}
      />
    );
  } else {
    return <MeterErrorDefaultIcon />;
  }
}
