import {IconButton, TextField, Tooltip} from '@material-ui/core';
import {AssignmentReturnOutlined as AssignmentReturnOutlinedIcon} from '@material-ui/icons';
import Axios from 'axios';
import MaterialTable from 'material-table';
import React, {useEffect, useState} from 'react';
import {tableIcons} from '../../styles/tableIcons';
import AdminAddSchoolForm from '../AdminAddSchoolForm';
import AdminUpdateSchoolForm from '../AdminUpdateSchoolForm';
import PopupForm from '../utils/PopupForm';

export function AdminSchoolsTable() {
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState({});

  useEffect(() => {
    const getSchools = async () => {
      const result = await Axios.get('/api/school');

      setSchools(result.data);
    };
    getSchools();
  }, []);

  const columns = [
    {title: 'Name', field: 'name'},
    {
      title: 'Signup Link',
      field: 'signupCode',
      render: (rowData) => {
        const baseUri = document.baseURI.split('/').slice(0, 3).join('/');
        const signupCode = rowData.signupCode ? rowData.signupCode : '';
        const schoolSignupLink = `${baseUri}/googleTeacher/${signupCode}`;
        return (
          <>
            <TextField
              size="small"
              disabled
              label="Classroom signup link"
              variant="outlined"
              value={schoolSignupLink}
            />
            <Tooltip title="Copy signup link">
              <IconButton
                onClick={() => navigator.clipboard.writeText(schoolSignupLink)}
              >
                <AssignmentReturnOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
  return (
    <div style={{maxWidth: '100%'}}>
      <MaterialTable
        actions={[
          {
            icon: () => (
              <PopupForm component={AdminAddSchoolForm} title="Create School" />
            ),
            tooltip: 'Add School',
            isFreeAction: true,
          },
          {
            icon: () => (
              <PopupForm
                component={AdminUpdateSchoolForm}
                school={school}
                title="Update School"
              />
            ),
            tooltip: 'update school',
            onClick: (event, rowData) => setSchool(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title={'STEMhero Schools'}
        columns={columns}
        data={schools}
        icons={tableIcons}
      />
    </div>
  );
}
