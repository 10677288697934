import {Button, Grid, Typography} from '@material-ui/core';
import {CheckCircle, Help, HighlightOff, ThumbUp} from '@material-ui/icons';
import Axios from 'axios';
import React, {Fragment, useState} from 'react';
import {useStepsStyles} from '../useStepsStyles';

export function MeasureExperimentComponent({
  meter,
  setMeter,
  setActiveStep,
  disableDataCollectionBtn,
  isMeterOwner,
}) {
  const classes = useStepsStyles(meter);
  const [onSuccess, setOnSuccess] = useState(false);

  const hasSufficientReadings =
    meter.readings.filter((r) => !r.deleted).length >= 3;

  const isDisabled =
    !isMeterOwner || (!hasSufficientReadings && !disableDataCollectionBtn);

  const handleClick = async () => {
    try {
      let updateMeter = {
        ...meter,
        currentStep: 7,
      };
      const result = await Axios.post(`/api/meter/${meter._id}`, updateMeter);

      setMeter(result.data);
      if (result.status === 200) {
        setOnSuccess(true);
      }
      setTimeout(() => {
        setActiveStep((prevState) => prevState + 1);
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <Button
        type="submit"
        value="updatedMeter"
        className={classes.root}
        variant="outlined"
        disabled={isDisabled}
        onClick={handleClick}
      >
        {onSuccess ? (
          <ThumbUp style={{color: 'white'}} />
        ) : isDisabled ? (
          <HighlightOff style={{color: 'grey'}} />
        ) : (
          <CheckCircle style={{color: 'white'}} />
        )}
      </Button>
      <Button
        className={classes.root}
        variant="outlined"
        component="a"
        href="https://meterhero.com/measure"
        target="_blank"
        rel="noopener"
      >
        <Help style={{color: 'white'}} />
      </Button>
      <Grid container>
        <Grid item xs={12}>
          {disableDataCollectionBtn && (
            <Typography color="error">Use your utility portal to measure changes in your consumption.</Typography>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}
