import {Button, Grid, MenuItem, TextField, Typography} from '@material-ui/core';
import {
  CheckCircle,
  HighlightOff,
  Help,
  ThumbUp,
} from '@material-ui/icons';
import Axios from 'axios';
import React, {Fragment, useEffect, useState} from 'react';
import {
  getMeterUnitTypes,
} from '../stepHelperFunction';
import {useStepsStyles} from '../useStepsStyles';

export function PredictUsageComponent({
  meter,
  setMeter,
  setActiveStep,
  isMeterOwner,
}) {
  const classes = useStepsStyles(meter);

  const [updatedMeter, setUpdatedMeter] = useState({
    expectedUsage: '',
    expectedUsageUnit: '',
  });

  const [onSuccess, setOnSuccess] = useState(false);

  const meterUnitType = getMeterUnitTypes(meter);

  useEffect(() => {
    if (meter) {
      setUpdatedMeter({
        expectedUsage: meter.expectedUsage,
        expectedUsageUnit: meter.expectedUsageUnit,
      });
    }
  }, [meter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newUpdatedMeter = {
      ...meter,
      currentStep: 1,
      ...updatedMeter,
    };
    try {
      const result = await Axios.post(
        `/api/meter/${meter._id}`,
        newUpdatedMeter,
      );
      setMeter(result.data);
      if (result.status === 200) {
        setOnSuccess(true);
      }
      setTimeout(() => {
        setActiveStep((prevState) => prevState + 1);
      }, 500);
      return () => {
        setUpdatedMeter({});
      };
    } catch (error) {
      console.error(error);
    }
  };

  const onChange = (e) => {
    setUpdatedMeter({...updatedMeter, [e.target.name]: e.target.value});
  };

  const isDisabled =
    !isMeterOwner ||
    !updatedMeter.expectedUsage ||
    !updatedMeter.expectedUsageUnit
      ? true
      : false;

  return (
    <Fragment>
      <Grid container wrap="nowrap" style={{marginTop: '18px'}} spacing={2}>
        <form onSubmit={handleSubmit} autoComplete="false">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Usage"
                name="expectedUsage"
                type="number"
                value={updatedMeter.expectedUsage}
                onChange={onChange}
                autoComplete="false"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Choose Unit"
                name="expectedUsageUnit"
                select
                value={updatedMeter.expectedUsageUnit}
                onChange={onChange}
                InputLabelProps={{
                  classes: {root: classes.step2TextField2},
                }}
                fullWidth={true}
                autoComplete="false"
              >
                {meterUnitType.map((m) => (
                  <MenuItem key={m.value} value={m.value}>
                    {m.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <br />
          <Button
            type="submit"
            value="updatedMeter"
            className={classes.root}
            variant="outlined"
            disabled={isDisabled}
          >
            {isDisabled ? (
              <HighlightOff style={{color: 'grey'}} />
            ) : onSuccess ? (
              <ThumbUp style={{color: 'white'}} />
            ) : (
              <CheckCircle style={{color: 'white'}} />
            )}
          </Button>
          <Button
            className={classes.root}
            variant="outlined"
            component="a"
            href="https://meterhero.com/predict"
            target="_blank"
            rel="noopener"
          >
            <Help style={{color: 'white'}} />
          </Button>
        </form>
      </Grid>
    </Fragment>
  );
}
