import axios from 'axios';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {tableIcons} from '../../styles/tableIcons';
import AddSponsorForm from '../AdminAddSponsorForm';
import UpdateSponsorForm from '../AdminUpdateSponsorForm';
import PopupForm from '../utils/PopupForm';

export function AdminSponsorsTable() {
  const [sponsors, setSponsors] = useState([]);
  const [sponsor, setSponsor] = useState({});

  useEffect(() => {
    const getSponsors = async () => {
      const result = await axios.get('/api/sponsor');
      setSponsors(result.data);
    };

    getSponsors();

    return () => {
      setSponsors([]);
    };
  }, []);

  const columns = [
    {title: 'Name', field: 'name'},
    {
      title: 'Created',
      field: 'created',
      type: 'time',
      render: (rowData) => moment(rowData.time).format('hh:mm A'),
    },
  ];

  return (
    <MaterialTable
      actions={[
        {
          icon: () => (
            <PopupForm component={AddSponsorForm} title="Create Sponsor" />
          ),
          tooltip: 'Add Sponsor',
          isFreeAction: true,
        },
        {
          icon: () => (
            <PopupForm
              component={UpdateSponsorForm}
              sponsor={sponsor}
              title="Update Sponsor"
            />
          ),
          tooltip: 'update sponsor',
          onClick: (event, rowData) => setSponsor(rowData),
        },
      ]}
      options={{
        actionsColumnIndex: -1,
      }}
      icons={tableIcons}
      columns={columns}
      data={sponsors}
      title="STEMhero Sponsors"
    />
  );
}
