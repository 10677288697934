import {makeStyles} from '@material-ui/core';

export const useStepsStyles = makeStyles(({palette}) => ({
  root: {
    marginRight: '4px',
    color: 'white',
    backgroundColor: palette.meterTypeColor,
    '&:hover': {
      opacity: '0.9',
      backgroundColor: palette.meterTypeColor,
    },
    '&:disabled': {
      opacity: '0.6',
      backgroundColor: 'lightgrey',
    },
  },
  btn: {
    color: palette.meterTypeColor,
  },
  btnSuccess: {
    color: 'white',
    backgroundColor: palette.meterTypeColor,
    borderRadius: '50%',
    padding: '2px',
  },
  column: {
    width: 'max-content',
  },
  step2TextField2: {
    top: '5px',
  },
  step2TextField3: {
    top: '-11px',
  },
}));
