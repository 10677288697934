import moment from 'moment';

export const calculateReadingValues = (meter) => {
  const isCalculatedFromValue = 
    meter.dataSource === 'meter' || meter.dataSource === '' || !meter.dataSource;

  let firstReadingAccountedFor = false;
  meter.readings.sort((a, b) => moment(a.time) - moment(b.time));
  let previousNonDeletedReading = 0;

  if (isCalculatedFromValue) {
    for (let i = 0; i < meter.readings.length; i++) {
      if (meter.readings[i].deleted) {
        continue;
      } // skip if reading deleted
      if (!firstReadingAccountedFor) {
        meter.readings[i].rawUsage = meter.readings[i].value;
        firstReadingAccountedFor = true;
        previousNonDeletedReading = i;
        continue;
      }
      let valueDiff = meter.readings[i].value - meter.readings[previousNonDeletedReading].value;
      meter.readings[i].rawUsage = valueDiff;
      previousNonDeletedReading = i;
    }
  } else {
    for (let i = 0; i < meter.readings.length; i++) {
      if (meter.readings[i].deleted) {
        continue;
      } // skip if reading deleted
      if (!firstReadingAccountedFor) {
        meter.readings[i].value = meter.readings[i].rawUsage;
        firstReadingAccountedFor = true;
        previousNonDeletedReading = i;
        continue;
      }
      meter.readings[i].value = meter.readings[previousNonDeletedReading].value + meter.readings[i].rawUsage;
      previousNonDeletedReading = i;
    }
  }
  return meter;
}
