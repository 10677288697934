import React from 'react';
import {isPortraitOrientation} from './isPortraitOrientation';

export function useScreenOrientation() {
  const [state, setState] = React.useState(isPortraitOrientation());

  React.useEffect(() => {
    const handleOrientationChange = () => setState(isPortraitOrientation());
    window.addEventListener('resize', handleOrientationChange);
    return () => window.removeEventListener('resize', handleOrientationChange);
  }, []);

  return state;
}
