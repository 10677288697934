import {
  Card,
  CardActions,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import {
  AccountCircleOutlined as SponsorsLinkIcon,
  GroupOutlined as UsersLinkIcon,
  HomeOutlined as HomeLinkIcon,
  ListAltOutlined as LogsLinkIcon,
} from '@material-ui/icons';
import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import Image from '../../assets/meterhero-logo-yellow.png';

import AdminUserInfoCard from '../../components/AdminUserInfoCard';
import AdminUserPage from '../../components/AdminUserPageContent';
import SponsorsTable from '../../components/AdminSponsorsTable';
import SchoolsTable from '../../components/AdminSchoolsTable';
import ClassroomTable from '../../components/AdminClassroomTable';

const drawerWidth = '300px';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    color: 'white',
    backgroundColor: '#263238',
    width: drawerWidth,
  },
  drawerHeader: {
    height: '30vh',
    padding: '1.5em 4em',
  },
  content: {
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export function AdminDashboard() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <br />
        <Container
          component="div"
          className={classes.drawerHeader}
          disableGutters={true}
        >
          <Card elevation={0} style={{backgroundColor: '#fff0'}}>
            <CardActions>
              <Link to={'/'}>
                <CardMedia component="img" image={Image} />
              </Link>
            </CardActions>
          </Card>
        </Container>

        <Divider />
        <List>
          <ListItem button component={Link} to={'/'}>
            <ListItemIcon>
              <HomeLinkIcon style={{color: 'white'}} />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>

          <ListItem button component={Link} to={'/admin/users'}>
            <ListItemIcon>
              <UsersLinkIcon style={{color: 'white'}} />
            </ListItemIcon>
            <ListItemText primary={'Users'} />
          </ListItem>

          <ListItem button component={Link} to={'/admin/logs'}>
            <ListItemIcon>
              <LogsLinkIcon style={{color: 'white'}} />
            </ListItemIcon>
            <ListItemText primary={'Logs'} />
          </ListItem>

          <ListItem button component={Link} to={'/admin/classrooms'}>
            <ListItemIcon>
              <HomeLinkIcon style={{color: 'white'}} />
            </ListItemIcon>
            <ListItemText primary={'Classrooms'} />
          </ListItem>

          <ListItem button component={Link} to={'/admin/schools'}>
            <ListItemIcon>
              <HomeLinkIcon style={{color: 'white'}} />
            </ListItemIcon>
            <ListItemText primary={'Schools'} />
          </ListItem>

          <ListItem button component={Link} to={'/admin/sponsors'}>
            <ListItemIcon>
              <SponsorsLinkIcon style={{color: 'white'}} />
            </ListItemIcon>
            <ListItemText primary={'Sponsors'} />
          </ListItem>
        </List>
        <Divider />
      </Drawer>

      <main className={classes.content}>
        <Switch>
          <Route path={'/admin/users'}>
            <Switch>
              <Route path={'/admin/users/:id'}>
                <AdminUserInfoCard />
              </Route>
              <Route path={'/admin'}>
                <AdminUserPage />
              </Route>
            </Switch>
          </Route>
          <Route path={'/admin/logs'}>
            <div>
              <h1>logs</h1>
            </div>
          </Route>
          <Route path={`/admin/sponsors`}>
            <SponsorsTable />
          </Route>
          <Route path={'/admin/classrooms'}>
            <ClassroomTable />
          </Route>
          <Route path={'/admin/schools'}>
            <SchoolsTable />
          </Route>
          <Route path={'/admin'}>
            <h1>admin dashboard</h1>
          </Route>
        </Switch>
      </main>
    </div>
  );
}
