import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Help,
} from '@material-ui/icons';
import React, { Fragment } from 'react';
import { useStepsStyles } from '../useStepsStyles';

export function ConstructEvidenceComponent({
  meter,
}) {
  const classes = useStepsStyles(meter);

  const hasSufficientReadings =
    meter.readings.filter((r) => !r.deleted).length >= 3;

  return (
    <Grid container direction="column" spacing={1}>
      <Fragment>
        <Grid container direction="row">
          <Typography>Turn in your final project to your teacher. Your teacher will share select student projects with your local utility sponsor.</Typography>
        </Grid>
        <Grid container direction="row">
          <Button
            className={classes.root}
            color="primary"
            variant="outlined"
            component="a"
            href="https://meterhero.com/communicate"
            target="_blank"
            rel="noopener"
          >
            <Help style={{ color: 'white' }} />
          </Button>
        </Grid>
      </Fragment>
    </Grid>
  );
}
