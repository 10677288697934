import {
  Button,
  Card,
  CardMedia,
  Container,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EcoIcon from '@material-ui/icons/Eco';
import ComputerIcon from '@material-ui/icons/Computer';
import DomainIcon from '@material-ui/icons/Domain';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import InfoIcon from '@material-ui/icons/Info';
import Axios from 'axios';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

const defaultSplashImage = 'meterhero.png';

const useStyles = makeStyles(({spacing, breakpoints}) => ({
  root: {
    backgroundColor: ' #d9d9d9ff',
    paddingBottom: '5px',
    [breakpoints.down('md')]: {
      width: 'fit-content',
    },
  },
  container: {
    minWidth: '100%',
    color: 'white',
  },
  section: {
    margin: 'auto',
    width: 'fit-content',
    minHeight: '100vh',
    paddingTop: '4em',
  },
  splashImageCard: {
    width: 'fit-content',
  },
  splashImageCardMedia: {
    width: 'unset',
    maxHeight: '60vh',
    height: 'auto',
    objectFit: 'contain',
    maxWidth: '100%',
  },
  sponsorsLinks: {
    margin: '10px auto 5px',
    display: 'flex',
    flexDirection: 'column',
  },
  sponsorLinkRows: {
    display: 'inline-flex',
    margin: '0 auto',
    '& > *': {
      margin: spacing(1),
    },
  },
}));

export function SplashPage({user}) {
  const classes = useStyles();
  const [sponsor, setSponsor] = useState({});

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const buttonProps = {
    size: isSmallScreen ? 'small' : 'large',
    target: '_blank',
    variant: 'contained',
    color: 'primary',
  };

  const ImageLinkUrl = sponsor.splashImage
    ? sponsor.splashImage.uri
      ? sponsor.splashImage.uri
      : defaultSplashImage
    : defaultSplashImage;

  useEffect(() => {
    if (!user.school) return;
    const getUserSponsor = async () => {
      const userSchool = await Axios.get(`/api/school/${user.school}`);
      const userSponsor = await Axios.get(
        `/api/sponsor/${userSchool.data.sponsor}`,
      );
      setSponsor(userSponsor.data);
    };
    getUserSponsor();
  }, [user.school]);

  return (
    <Container disableGutters maxWidth={false} className={classes.root}>
      <Container component="div" className={classes.container}>
        <Container component="div" className={classes.section}>
          <Card className={classes.splashImageCard}>
            <CardMedia
              className={classes.splashImageCardMedia}
              component="img"
              image={ImageLinkUrl}
            />
          </Card>
          <div className={classes.sponsorsLinks}>
            <div className={classes.sponsorLinkRows}>
              {sponsor.aboutUrl && (
                  <Button
                    {...buttonProps}
                    href={sponsor.aboutUrl}
                    startIcon={<InfoIcon />}
                  >
                    <span style={{flex: 'none'}}>LOCAL UTILITY</span>
                  </Button>
              )}
              {sponsor.about2Url && (
                <Button
                  {...buttonProps}
                  href={sponsor.about2Url}
                  startIcon={<InfoIcon />}
                >
                  <span style={{flex: 'none'}}>LOCAL UTILITY</span>
                </Button>
              )}
              {sponsor.regionalAboutUrl && (
                <Button
                  {...buttonProps}
                  href={sponsor.regionalAboutUrl}
                  startIcon={<AccountBalanceIcon />}
                >
                  <span style={{flex: 'none'}}>REGIONAL PROVIDER</span>
                </Button>
              )}
            </div>
            <div className={classes.sponsorLinkRows}>
              {sponsor.tourUrl && (
                <Button
                  {...buttonProps}
                  href={sponsor.tourUrl}
                  startIcon={<ComputerIcon />}
                >
                  <span style={{flex: 'none'}}>VIRTUAL TOUR</span>
                </Button>
              )}
              {sponsor.programsUrl && (
                <Button
                  {...buttonProps}
                  href={sponsor.programsUrl}
                  startIcon={<EcoIcon />}
                >
                  <span style={{flex: 'none'}}>CONSERVATION PROGRAMS</span>
                </Button>
              )}
            </div>
            <div className={classes.sponsorLinkRows}>
              {sponsor.careersUrl && (
                <Button
                  {...buttonProps}
                  href={sponsor.careersUrl}
                  startIcon={<DomainIcon />}
                >
                  <span style={{flex: 'none'}}>CAREERS</span>
                </Button>
              )}
              {sponsor.socialUrl && (
                <Button
                  {...buttonProps}
                  href={sponsor.socialUrl}
                  startIcon={<EmojiPeopleIcon />}
                >
                  <span style={{flex: 'none'}}>SOCIAL</span>
                </Button>
              )}
              {sponsor.websiteUrl && (
                <Button
                  {...buttonProps}
                  href={sponsor.websiteUrl}
                  startIcon={<ComputerIcon />}
                >
                  <span style={{flex: 'none'}}>UTILITY PORTAL</span>
                </Button>
              )}
            </div>
          </div>
        </Container>
      </Container>
    </Container>
  );
}

SplashPage.propTypes = {
  user: PropTypes.object.isRequired,
};
