import {Container, Grid, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Axios from 'axios';
import React, {useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {calculateReadingValues} from '../../utils/calculateReadingValues';
import DashboardUsageChart from '../DashboardUsageChart';
import DashboardUserDeletedReadingsTable from '../DashboardUserDeletedReadingsTable';
import DashboardUserMeterReadingsTable from '../DashboardUserMeterReadingsTable';
import ProjectPlanStepper from '../ProjectPlanStepper';

const useStyles = makeStyles(({spacing, palette}) => ({
  container: {
    padding: '8px',
    backgroundColor: 'white',
  },
  paper: {
    padding: spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.meterTypeColor,
  },
}));

export function DashboardContent({user, meter, setMeter}) {
  const classes = useStyles(meter);

  const {params} = useRouteMatch('/:id');

  const isMeterOwner = user._id === meter.user;

  useEffect(() => {
    const loadMeter = async () => {
      const result = await Axios.get(`/api/meter/${params.id}`);
      const meterData = calculateReadingValues(result.data);
      setMeter(meterData);
    };
    loadMeter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <Container className={classes.container} maxWidth={false}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <ProjectPlanStepper user={user} meter={meter} setMeter={setMeter} />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.chart}>
            <DashboardUsageChart meter={meter} />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <DashboardUserMeterReadingsTable
              user={user}
              meter={meter}
              setMeter={setMeter}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <DashboardUserDeletedReadingsTable
              user={user}
              meter={meter}
              setMeter={setMeter}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
