import {makeStyles} from '@material-ui/core';
import {RestoreFromTrash as RestoreFromTrashIcon} from '@material-ui/icons';
import axios from 'axios';
import MaterialTable, {MTableToolbar} from 'material-table';
import moment from 'moment';
import React from 'react';
import {calculateReadingValues} from '../../utils/calculateReadingValues';
import {tableIcons} from '../../styles/tableIcons';

const useStyles = makeStyles(({palette}) => ({
  toolbar: {
    paddingRight: '8px',
    textTransform: 'capitalize',
    color: 'white',
    background: palette.meterTypeColor,
  },
}));

export const DashboardUserDeletedReadingsTable = ({meter, user, setMeter}) => {
  const classes = useStyles(meter);

  const tableReadings = meter.readings?.filter((reading) => {
    return reading.deleted === true;
  });

  const columns = [
    {
      title: 'Date',
      field: 'time',
      type: 'date',
      render: (rowData) => moment(rowData.time).format('LL'),
      defaultSort: 'desc',
    },
    {
      title: 'Time',
      field: 'time',
      type: 'time',
      render: (rowData) => moment(rowData.time).format('hh:mm A'),
    },
    {
      title: 'Value',
      field: 'value',
      type: 'numeric',
      render: (rowData) =>
        rowData
          ? rowData.value
            ? Number.parseFloat(rowData.value).toFixed(2)
            : 'N/A'
          : 0,
    },
    {
      title: 'Consumption', // same as "Raw Usage"
      field: 'rawUsage',
      type: 'numeric',
      render: (rowData) =>
        rowData ? (rowData.rawUsage ? rowData.rawUsage.toFixed(2) : 'N/A') : '',
    },
    {
      title: 'Date deleted',
      field: 'updated',
      type: 'time',
      render: (rowData) => moment(rowData.time).format('llll'),
    },
  ];

  return (
    <MaterialTable
      icons={tableIcons}
      components={{
        Toolbar: (props) => (
          <div className={classes.toolbar}>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
      data={tableReadings}
      columns={columns}
      title={'Deleted Readings'}
      options={{
        search: false,
        paging: false,
        minBodyHeight: '400px',
        pageSize: 5,
        actionsColumnIndex: -1,
        addRowPosition: 'first',
        actionsCellStyle: {
          paddingLeft: '20px',
        },
      }}
      actions={[
        {
          icon: () => <RestoreFromTrashIcon />,
          tooltip: 'restore reading',
          onClick: async (event, rowData) => {
            let meterUpdate = {...meter}; // copy meter
            let index = meterUpdate.readings.indexOf(rowData);
            const softDeletedReading = {
              ...rowData,
              deleted: false,
            };
            meterUpdate.readings[index] = softDeletedReading;
            meterUpdate = calculateReadingValues(meterUpdate);
            try {
              const result = await axios.post(
                `/api/meter/${meter._id}`,
                meterUpdate,
              );
              setMeter(calculateReadingValues(result.data));
            } catch (error) {
              console.error(error);
            }
          },
        },
      ]}
    />
  );
};
