import {Button, TextField} from '@material-ui/core';
import {CheckCircle, Help, HighlightOff, ThumbUp} from '@material-ui/icons';
import Axios from 'axios';
import React, {Fragment, useEffect, useState} from 'react';
import {useStepsStyles} from '../useStepsStyles';
import {calculateReadingValues} from '../../../utils/calculateReadingValues';

export function DesignExperimentComponent({
  meter,
  setMeter,
  setActiveStep,
  disableDataCollectionBtn,
  isMeterOwner,
}) {
  const classes = useStepsStyles(meter);
  const [updatedMeter, setUpdatedMeter] = useState({});

  const [onSuccess, setOnSuccess] = useState(false);

  const hasSufficientReadings =
    meter.readings.filter((r) => !r.deleted).length >= 3;

  const datePickerSet = Object.keys(updatedMeter).length ? false : true;

  const isDisabled =
    !isMeterOwner ||
    (!hasSufficientReadings && !disableDataCollectionBtn) ||
    datePickerSet;

  useEffect(() => {
    if (meter.experimentStart) {
      setUpdatedMeter({
        experimentStart: meter.experimentStart,
      });
    }
    return () => setUpdatedMeter({});
  }, [meter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newUpdatedMeter = {
      ...meter,
      currentStep: 6,
      ...updatedMeter,
    };
    try {
      const result = await Axios.post(
        `/api/meter/${meter._id}`,
        newUpdatedMeter,
      );

      setMeter(calculateReadingValues(result.data));

      if (result.status === 200) {
        setOnSuccess(true);
      }
      setTimeout(() => {
        setActiveStep((prevState) => prevState + 1);
      }, 700);
      return () => {
        setUpdatedMeter({});
      };
    } catch (error) {
      console.error(error);
    }
  };

  const onChange = (e) => {
    setUpdatedMeter({...updatedMeter, [e.target.name]: e.target.value});
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Experiment start date"
          name="experimentStart"
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChange}
          value={updatedMeter.experimentStart}
          defaultValue={Date.now}
        />
        <br />
        <br />
        <Button
          type="submit"
          value="est"
          className={classes.root}
          disabled={isDisabled}
          variant="outlined"
        >
          {onSuccess ? (
            <ThumbUp style={{color: 'white'}} />
          ) : isDisabled ? (
            <HighlightOff style={{color: 'grey'}} />
          ) : (
            <CheckCircle style={{color: 'white'}} />
          )}
        </Button>
        <Button
          className={classes.root}
          variant="outlined"
          component="a"
          href="https://meterhero.com/design"
          target="_blank"
          rel="noopener"
        >
          <Help style={{color: 'white'}} />
        </Button>
      </form>
    </Fragment>
  );
}
