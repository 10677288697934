import {
  Card,
  CardActions,
  CardMedia,
  Container,
  Snackbar,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {ArrowForwardIos, Close, ScreenRotation} from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import clsx from 'clsx';
import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import Image from '../../assets/meterhero-logo-yellow.png';
import DashboardContent from '../../components/DashboardContent';
import {MeterListItems} from '../../components/DashboardSideBar/MeterListItems';
import SideBarTeacherInputs from '../../components/SideBarTeacherInputs';
import SideBarThreeDotNavMenu from '../../components/SideBarThreeDotNavMenu';
import SplashPage from '../../components/SplashPage';
import {useScreenOrientation} from '../../utils/useScreenOrientation';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: theme.palette.background.drawer,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.drawer,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  drawerHeaderLogo: {
    padding: '10px 65px',
  },
  sideBarHeaderCard: {
    backgroundColor: '#fff0',
  },
  appBarText: {
    margin: [[0, 'auto']],
    color: 'lightgrey',
  },
  listItems: {
    color: 'lightgrey',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const [user, setUser] = React.useState({});
  const [meters, setMeters] = React.useState([]);
  const [classroomMeters, setClassroomMeters] = React.useState([]);
  const [meter, setMeter] = React.useState({});
  const [userSchool, setUserSchool] = React.useState({});
  const [open, setOpen] = React.useState(true);
  const [activeItem, setActiveItem] = React.useState('');
  const [filteredClassroomMeters, setFilteredClassroomMeters] = React.useState(
    [],
  );
  const isPortrait = useScreenOrientation();
  const isTeacher = user.role === 'teacher';

  const text = React.useRef('');
  const filterMeters = (text) => {
    const result = classroomMeters.filter((meter) => {
      const regex = new RegExp(`${text}`, 'gi');

      return meter.username.match(regex);
    });
    return result;
  };

  const onSearchChange = (e) =>
    setFilteredClassroomMeters(filterMeters(e.target.value));

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  React.useEffect(() => {
    const loadUser = async () => {
      const result = await axios.get('/api/user/me');

      setUser(result.data);
    };

    loadUser();
  }, []);

  React.useEffect(() => {
    if (!user.school) return;
    const getSchoolName = async (params) => {
      let userSchoolId = user.school;

      try {
        const userSchool = await axios.get(`/api/school/${userSchoolId}`);

        setUserSchool(userSchool.data);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolName();
  }, [user.school]);

  React.useEffect(() => {
    if (!user) return;

    const loadMeters = async () => {
      const dbResult = await axios.get('/api/meter');
      setMeters(dbResult.data);
    };

    loadMeters();
  }, [meter, user]);

  React.useEffect(() => {
    if (!user.classroom) return;

    const getClassroomMeters = async () => {
      const result = await axios.get(
        `/api/classroom/${user.classroom._id}/meters`,
      );

      const sortedMeterList = result.data.sort((a, b) => {
        // Count only non-deleted readings
        const aLen = a.readings.reduce((a, r) => (r.deleted ? a : a + 1), 0);
        const bLen = b.readings.reduce((a, r) => (r.deleted ? a : a + 1), 0);
        return bLen - aLen;
      });
      setClassroomMeters(sortedMeterList);
    };
    getClassroomMeters();
  }, [user.classroom]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerContent = (
    <React.Fragment>
      <Container component="div" className={classes.drawerHeaderLogo}>
        <Card className={classes.sideBarHeaderCard} elevation={0}>
          <CardActions>
            <Link
              to={'/'}
              onClick={() => {
                setActiveItem('');
              }}
            >
              <CardMedia component="img" image={Image} />
            </Link>
          </CardActions>
        </Card>
      </Container>

      <Divider />

      <Divider />
      <List className={classes.listItems}>
        {
          <MeterListItems
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            metersArr={meters}
          />
        }
      </List>
      <Divider />

      {isTeacher ? (
        SideBarTeacherInputs(user, setUser, text, onSearchChange)
      ) : (
        <Container component="div" style={{padding: '8px 16px'}}>
          <Typography className={classes.listItems} variant="body1">
            {`Classmates' meters`}
          </Typography>
        </Container>
      )}

      <Divider />

      <List className={classes.listItems}>
        {filteredClassroomMeters.length ? (
          <MeterListItems
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            metersArr={filteredClassroomMeters}
          />
        ) : (
          <MeterListItems
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            metersArr={classroomMeters}
          />
        )}
      </List>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <ArrowForwardIos color="primary" />
          </IconButton>
          <Typography className={classes.appBarText} variant="h6">
            {userSchool.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <SideBarThreeDotNavMenu user={user} />

          <IconButton color="primary" onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        {drawerContent}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route exact path={'/'}>
            <SplashPage user={user} />
          </Route>

          <Route path={'/:meterId'}>
            <DashboardContent user={user} meter={meter} setMeter={setMeter} />
          </Route>
        </Switch>
      </main>
      {isPortrait && (
        <Snackbar open={open} onClose={handleClose}>
          <Alert
            severity="info"
            icon={<ScreenRotation />}
            onClick={handleClose}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <Close fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          >
            Rotate screen
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
