import Axios from 'axios';
import MaterialTable from 'material-table';
import React, {useEffect, useState} from 'react';
import PopupForm from '../utils/PopupForm';
import {tableIcons} from '../../styles/tableIcons';
import AddClassroomForm from '../AdminAddClassroomForm';

export function AdminClassroomTable() {
  const [classrooms, setClassrooms] = useState([]);
  const columns = [{title: 'Name', field: 'name'}];

  useEffect(() => {
    const getClassrooms = async () => {
      const result = await Axios.get('/api/classroom');

      setClassrooms(result.data);
    };
    getClassrooms();
  }, []);

  return (
    <div style={{maxWidth: '100%'}}>
      <MaterialTable
        actions={[
          {
            icon: () => (
              <PopupForm
                component={AddClassroomForm}
                title="Create Classroom"
              />
            ),
            tooltip: 'Add Classroom',
            isFreeAction: true,
          },
        ]}
        title={'STEMhero Classrooms'}
        columns={columns}
        data={classrooms}
        icons={tableIcons}
      />
    </div>
  );
}
