import {withStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';

export const DarkBgTextField = withStyles((theme) => ({
  root: {
    '& input': {
      color: 'white',
    },
    '& label': {
      color: theme.palette.stemhero.blue,
    },
    '& label.Mui-focused': {
      color: theme.palette.stemhero.blue,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.stemhero.blue,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: theme.palette.stemhero.blue,
    },
  },
}))(TextField);
