import {
  Card,
  CardActions,
  CardMedia,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import {
  GroupOutlined as UsersLinkIcon,
  HomeOutlined as HomeLinkIcon,
} from '@material-ui/icons';
import Axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import Image from '../../assets/meterhero-logo-yellow.png';
import TeacherClassroomsTable from '../../components/TeacherClassroomsTable';

const drawerWidth = '300px';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    color: 'white',
    backgroundColor: '#263238',
    width: drawerWidth,
  },
  drawerHeader: {
    height: '30vh',
    padding: '1.5em 4em',
  },
  content: {
    flexGrow: 1,
    height: '100%',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export function TeacherDashboard() {
  const classes = useStyles();

  const [user, setUser] = useState({});

  useEffect(() => {
    const loadUser = async () => {
      const result = await Axios.get('/api/user/me');

      setUser(result.data);
    };

    loadUser();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <br />
        <Container
          component="div"
          className={classes.drawerHeader}
          disableGutters={true}
        >
          <Card elevation={0} style={{backgroundColor: '#fff0'}}>
            <CardActions>
              <Link to={'/'}>
                <CardMedia component="img" image={Image} />
              </Link>
            </CardActions>
          </Card>
        </Container>

        <Divider />
        <List>
          <ListItem button component={Link} to={'/'}>
            <ListItemIcon>
              <HomeLinkIcon style={{color: 'white'}} />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>

          <ListItem button component={Link} to={'/teacher/classrooms'}>
            <ListItemIcon>
              <UsersLinkIcon style={{color: 'white'}} />
            </ListItemIcon>
            <ListItemText primary={'Classrooms'} />
          </ListItem>
        </List>
        <Divider />
      </Drawer>

      <main className={classes.content}>
        <Switch>
          <Route path={'/teacher'}>
            <Switch>
              <Route path={'/teacher/classrooms'}>
                <TeacherClassroomsTable user={user} />
              </Route>
              <Route path={'/teacher'}>
                <h1>hello</h1>
              </Route>
            </Switch>
          </Route>
        </Switch>
      </main>
    </div>
  );
}
